<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <h3 class="card-label">
          {{ $t("STOCK_PURCHASE_ORDER_PREPARE") }}
        </h3>
      </template>
      <template v-slot:body>
        <b-row>
          <StockInfoPanels
            v-if="!firstLoader"
            :number="stockPurchaseOrder.id"
            :fromStoreName="stockPurchaseOrder.fromStoreName"
            :toStoreName="stockPurchaseOrder.toStoreName"
            :userName="stockPurchaseOrder.userName"
            :statusName="stockPurchaseOrder.statusName"
          >
          </StockInfoPanels>
          <b-col :sm="12">
            <StockPurchaseOrderPrepareItemsTable
              v-if="!firstLoader && _.size(stockPurchaseOrder) > 0"
              :headers="headers"
              :items="stockPurchaseOrder.requestedItems"
              :statusId="stockPurchaseOrder.statusId"
              :selectedItems="selectedItems"
              :showTotalPrice="showTotalPrice"
            >
            </StockPurchaseOrderPrepareItemsTable>
          </b-col>

          <b-col
            :sm="12"
            v-if="!firstLoader && stockPurchaseOrder.actionNotes != ''"
          >
            <v-alert border="top" colored-border type="info" elevation="2">
              <div class="text-h6">{{ $t("NOTES") }}:</div>
              {{ stockPurchaseOrder.actionNotes }}
            </v-alert>
          </b-col>
        </b-row>
        <b-form>
          <SkeletonLoaderMain :visible="firstLoader"></SkeletonLoaderMain>
          <v-overlay :value="!firstLoader && isLoadingStock == true">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>

        <BackButton></BackButton>
        <CustomButton
          v-if="
            stockPurchaseOrder.statusId == 2 || stockPurchaseOrder.statusId == 3
          "
          :label="$t('REJECT')"
          :isLoading="isLoadingStock"
          :iconClass="'fa fa-times-circle'"
          :colorClass="'btn btn-danger'"
          v-on:save="reject($event)"
        ></CustomButton>
        <CustomButton
          v-if="stockPurchaseOrder.statusId == 1"
          :label="$t('CANCEL')"
          :isLoading="isLoadingStock"
          :iconClass="'fa fa-times'"
          :colorClass="'btn btn-warning'"
          v-on:save="cancel($event)"
        ></CustomButton>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools">
          <CustomButton
            v-if="
              stockPurchaseOrder.statusId == 1 ||
                stockPurchaseOrder.statusId == 2
            "
            :label="$t('PRINT')"
            :isLoading="isLoadingStock"
            :iconClass="'fa fa-print'"
            :colorClass="'btn btn-warning font-weight-bolder mr-2'"
            v-on:save="prepareStockXLS($event)"
          ></CustomButton>
          <SaveButton
            v-if="stockPurchaseOrder.statusId == 1"
            :isLoading="isLoadingStock"
            v-on:save="onSave($event)"
          ></SaveButton>
          <CustomButton
            v-if="stockPurchaseOrder.statusId == 2"
            :label="$t('READY_TO_SHIP')"
            :isLoading="isLoadingStock"
            :iconClass="'fa fa-check'"
            :colorClass="'btn btn-primary'"
            :isDisabled="selectedItemsNotEnoughStock"
            v-on:save="readyToShip($event)"
          ></CustomButton>

          <CustomButton
            v-if="stockPurchaseOrder.statusId == 3"
            :label="$t('BACK_TO_PROCESSING')"
            :isLoading="isLoadingStock"
            :iconClass="'ki ki-long-arrow-back icon-xs'"
            :colorClass="'btn btn-primary'"
            v-on:save="backToProcessing($event)"
          ></CustomButton>
          <CustomButton
            v-if="stockPurchaseOrder.statusId == 3"
            :label="$t('SHIP')"
            :isLoading="isLoadingStock"
            :iconClass="'fa fa-truck'"
            :colorClass="'btn btn-success'"
            :isDisabled="selectedItemsNotEnoughStock"
            v-on:save="ship($event)"
          ></CustomButton>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import StockInfoPanels from "@/modules/stock/components/StockInfoPanels.vue";
import SaveButton from "@/view/content/forms/components/SaveButton.vue";
import CustomButton from "@/view/content/forms/components/CustomButton.vue";

import StockPurchaseOrderPrepareItemsTable from "@/modules/stock/components/purchase-orders/StockPurchaseOrderPrepareItemsTable.vue";
import {
  FETCH_STOCK_PURCHASE_ORDER_PREPARE,
  EXPORT_STOCK_REDIRECTING_XLS,
  PREPARE_STOCK_XLS,
  SAVE_STOCK_PURCHASE_ORDER_ITEMS
} from "@/modules/stock/store/stock.module";
import { READY_TO_SHIP_STOCK_PURCHASE_ORDER } from "@/modules/stock/store/stock.module";
import { BACK_TO_PROCESSING_STOCK_PURCHASE_ORDER } from "@/modules/stock/store/stock.module";
import { REJECT_STOCK_PURCHASE_ORDER } from "@/modules/stock/store/stock.module";
import { CANCEL_STOCK_PURCHASE_ORDER } from "@/modules/stock/store/stock.module";
import { SHIP_STOCK_PURCHASE_ORDER } from "@/modules/stock/store/stock.module";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

import AuthService from "@/core/services/auth.service";

export default {
  name: "StockPurchaseOrderPrepare",
  mixins: [],
  data() {
    return {
      errors: {},
      firstLoader: true,
      stockPurchaseOrder: {},
      showTotalPrice: false,
      headers: [
        {
          text: this.$t("ITEM"),
          align: "start",
          filterable: true,
          value: "name"
        },
        {
          text: this.$t("STOCK_PURCHASE_ORDER_REQUESTED_UNIT_VALUE"),
          value: "unitValue",
          sortable: true
        },
        {
          text: this.$t("STOCK_PURCHASE_ORDER_READY_TO_SEND"),
          value: "totalSend",
          sortable: true
        },
        {
          text: this.$t("LOTS"),
          value: "lots",
          sortable: true
        }
      ]
    };
  },
  components: {
    KTCard,
    StockInfoPanels,
    StockPurchaseOrderPrepareItemsTable,
    SaveButton,
    CustomButton
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Stock purchase order list",
        route: { name: "stock-purchase-orders-list" }
      }
    ]);

    vm.getStockPurchaseOrderPrepare();
  },
  computed: {
    ...mapGetters(["isLoadingStock", "currentUser"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    //Това сега не го ползваме, направо правим selectedItems да е Payload-a
    selectedItemsWithNestedLotsInItem() {
      let vm = this;
      // let selectedItems = [];
      let requestedItems = vm.stockPurchaseOrder.requestedItems;
      const selectedItems = requestedItems
        .map(item => {
          const filteredLots = item.lots.filter(lot => lot.sendUnitValue > 0);
          return { ...item, lots: filteredLots };
        })
        .filter(item => item.lots.length > 0);
      return selectedItems;
    },
    selectedItems() {
      let vm = this;
      let requestedItems = vm.stockPurchaseOrder.requestedItems;
      const filteredData = requestedItems.flatMap(item => {
        return item.lots
          .filter(lot => lot.sendUnitValue > 0)
          .map(lot => {
            return {
              requestedItemId: item.requestedItemId,
              itemId: item.id,
              unitId: item.unitId,
              stockLotId: lot.stockLotId,
              stockLot: lot.stockLot,
              stockLotExpDate: lot.stockLotExpDate,
              sendUnitValue: lot.sendUnitValue,
              stockUnitValue: lot.stockUnitValue,
              sumTotalBookedLot: lot.sumTotalBookedLot,
              sumOtherBookedLot: lot.sumOtherBookedLot,
              availableForBookLot: lot.availableForBookLot
            };
          });
      });
      return filteredData;
    },
    selectedItemsNotEnoughStock() {
      let vm = this;
      return vm.selectedItems.some(
        item =>
          parseFloat(item.sendUnitValue) > parseFloat(item.availableForBookLot)
      );
    },
    payload() {
      let vm = this;
      let payload = {};
      payload.stockPurchaseOrderId = vm.stockPurchaseOrder.id;
      payload.items = vm.selectedItems;
      console.log(vm.selectedItems);
      return payload;
    }
  },
  methods: {
    getStockPurchaseOrderPrepare() {
      let vm = this;
      let id = this.$route.params.id;
      this.$store
        .dispatch(FETCH_STOCK_PURCHASE_ORDER_PREPARE, id)
        .then(data => {
          if (
            data.data.statusId != 1 &&
            data.data.statusId != 2 &&
            data.data.statusId != 3
          ) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            if (data.data.statusId == 4) {
              //Ако е за получаване
              this.$router.push({
                name: "stock-purchase-order-receiving",
                params: {
                  id: data.data.id
                }
              });
            } else {
              this.$router.push({ name: "stock-purchase-orders-list" });
            }
          } else {
            vm.firstLoader = false;
            vm.$nextTick(function() {
              vm.stockPurchaseOrder = data.data;
            });
          }
        })
        .catch(response => {
          // if (response.status === 404) {
          vm.$notify({
            group: "notify",
            type: "error",
            text: response.data.message
          });
          this.$router.push({ name: "stock-purchase-orders-list" });
          // }
        });
    },
    onSave() {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          let payload = vm.payload;
          console.log(payload);
          vm.$store
            .dispatch(SAVE_STOCK_PURCHASE_ORDER_ITEMS, payload)
            .then(data => {
              vm.errors = data.data.errors;
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              vm.stockPurchaseOrder = data.data;

              AuthService.refreshUserData();
            })
            .catch(function(response) {
              vm.errors = response.data.errors;
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
            });
        })
        .catch(function() {});
    },
    prepareStockXLS() {
      let vm = this;
      vm.isPDFloading = true;
      vm.isLoadingStoreDailyTasks = true;
      let params = {
        id: vm.$route.params.id,
        store: vm.stockPurchaseOrder.fromStoreName
      };
      // let params = this.params;
      // params.id = this.$route.params.id;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(PREPARE_STOCK_XLS, apiParams)
        .then(data => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            let blob = new Blob([data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download =
              "prepare-stock-" +
              vm.$route.params.id +
              "-" +
              vm.stockPurchaseOrder.fromStoreName;
            link.click();
            link.remove();
            vm.dialogXML = false;
            if (vm.isPageLoading == true || vm.isXMLloading == true) {
              vm.isPageLoading = false;
              vm.isXMLloading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    },
    readyToShip() {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          let payload = vm.payload;
          vm.$store
            .dispatch(READY_TO_SHIP_STOCK_PURCHASE_ORDER, payload)
            .then(data => {
              vm.errors = data.data.errors;
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              vm.stockPurchaseOrder = data.data;

              AuthService.refreshUserData();
            })
            .catch(function(response) {
              vm.errors = response.data.errors;
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
            });
        })
        .catch(function() {});
    },
    backToProcessing() {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          let payload = {
            stockPurchaseOrderId: vm.stockPurchaseOrder.id
          };
          vm.$store
            .dispatch(BACK_TO_PROCESSING_STOCK_PURCHASE_ORDER, payload)
            .then(data => {
              vm.errors = data.data.errors;
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              vm.stockPurchaseOrder = data.data;

              AuthService.refreshUserData();
            })
            .catch(function(response) {
              vm.errors = response.data.errors;
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
            });
        })
        .catch(function() {});
    },
    reject() {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          let payload = {
            stockPurchaseOrderId: vm.stockPurchaseOrder.id
          };
          vm.$store
            .dispatch(REJECT_STOCK_PURCHASE_ORDER, payload)
            .then(data => {
              vm.errors = data.data.errors;
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              AuthService.refreshUserData();
              vm.$router.push({ name: "stock-purchase-orders-list" });
            })
            .catch(function(response) {
              vm.errors = response.data.errors;
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
            });
        })
        .catch(function() {});
    },
    cancel() {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          let payload = {
            stockPurchaseOrderId: vm.stockPurchaseOrder.id
          };
          vm.$store
            .dispatch(CANCEL_STOCK_PURCHASE_ORDER, payload)
            .then(data => {
              vm.errors = data.data.errors;
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              AuthService.refreshUserData();
              vm.$router.push({ name: "stock-purchase-orders-list" });
            })
            .catch(function(response) {
              vm.errors = response.data.errors;
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
            });
        })
        .catch(function() {});
    },
    ship() {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          let payload = {
            stockPurchaseOrderId: vm.stockPurchaseOrder.id
          };
          vm.$store
            .dispatch(SHIP_STOCK_PURCHASE_ORDER, payload)
            .then(data => {
              vm.errors = data.data.errors;
              vm.$nextTick(function() {
                vm.stockPurchaseOrder = data.data;
                vm.exportStockRedirectingXls();
              });
            })
            .catch(function(response) {
              vm.errors = response.data.errors;
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
              vm.getStockPurchaseOrderPrepare();
            });
        })
        .catch(function() {});
    },
    exportStockRedirectingXls() {
      let vm = this;
      vm.isPDFloading = true;
      //copy current params to modify
      vm.isLoadingStoreDailyTasks = true;
      let params = {
        stockActionId: vm.stockPurchaseOrder.stockActionSent.id
      };
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(EXPORT_STOCK_REDIRECTING_XLS, apiParams)
        .then(data => {
          vm.firstLoader = false;
          vm.$nextTick(function() {
            let blob = new Blob([data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download =
              "stock-movement-" + vm.stockPurchaseOrder.stockActionSent.id;
            link.click();
            link.remove();
            vm.dialogXML = false;
            if (vm.isPageLoading == true || vm.isXMLloading == true) {
              vm.isPageLoading = false;
              vm.isXMLloading = false;
            }
            AuthService.refreshUserData();
            this.$router.push({
              name: "stock-purchase-order-review",
              params: { id: vm.stockPurchaseOrder.id }
            });
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    }
  }
};
</script>
