:search="search"
<template>
  <div>
    <v-card-title>
      <v-text-field
        v-model="search"
        clearable
        append-icon="mdi-magnify"
        :label="$t('SEARCH')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :items-per-page="itemsPerPage"
      :item-class="checkDiscrepancy"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPage: itemsPerPage,
        itemsPerPageOptions: rowsPerPageItems,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right'
      }"
    >
      <template v-slot:item.name="{ item }">
        <strong>{{ item.warehouseName }}</strong> | {{ item.name }}
        <small>({{ item.unitShortName }})</small>
      </template>
      <template v-slot:item.unitValue="{ item }">
        ({{
          (parseFloat(item.unitValue) / parseFloat(item.packageValue)).toFixed(
            2
          )
        }}
        {{ item.packageName }}) <strong>{{ item.unitValue }}</strong>
        {{ item.unitShortName }}
      </template>
      <template v-slot:item.totalSend="{ item }">
        ({{
          (
            parseFloat(sumSendUnitValue(item)) / parseFloat(item.packageValue)
          ).toFixed(2)
        }}
        {{ item.packageName }})
        <strong>{{ sumSendUnitValue(item) }} {{ item.unitShortName }}</strong>
      </template>
      <template v-slot:item.lots="{ item }">
        <template v-for="lot in item.lots">
          <v-row v-bind:key="'lot' + lot.stockLotId">
            <template v-if="statusId != 3">
              <v-col cols="12" sm="2">
                <div>
                  <strong>{{ $t("IN_STOCK") }}:</strong><br />
                  {{ lot.stockUnitValue }} {{ item.unitShortName }}
                </div>
              </v-col>
              <v-col cols="12" sm="2">
                <div>
                  <strong>{{ $t("OTHER_BOOKED_STOCK") }}:</strong><br />
                  {{ lot.sumOtherBookedLot }} {{ item.unitShortName }}
                </div>
              </v-col>

              <v-col cols="12" sm="2">
                <div>
                  <strong>{{ $t("AVAILABLE_FOR_BOOKING_STOCK") }}:</strong
                  ><br />
                  {{ lot.availableForBookLot }} {{ item.unitShortName }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  ref="ids"
                  @keydown.enter="switchInput"
                  hide-details
                  type="number"
                  :label="setLabel(item, lot)"
                  v-model="lot.sendPackageValue"
                  :suffix="item.packageName"
                  :step="item.unitNumberType == 2 ? 1 : 0.001"
                  min="0"
                  hide-spin-buttons
                  @keydown.native="preventArrowKeys"
                  @focus="$event.target.select()"
                  @blur="filterPackageValue(item, lot)"
                >
                  <template v-slot:prepend v-if="notEnoughStock(lot)">
                    <v-tooltip color="danger" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="text-danger" v-bind="attrs" v-on="on"
                          >fa fa-exclamation-circle</v-icon
                        >
                      </template>
                      <span>{{ $t("NOT_ENOUGH_STOCK") }}</span>
                    </v-tooltip>
                  </template></v-text-field
                >
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  hide-details
                  type="number"
                  :label="setLabel(item, lot)"
                  v-model="lot.sendUnitValue"
                  :suffix="item.unitShortName"
                  :step="item.unitNumberType == 2 ? 1 : 0.001"
                  min="0"
                  hide-spin-buttons
                  @keydown.native="preventArrowKeys"
                  @focus="$event.target.select()"
                  @blur="filterUnitValue(item, lot)"
                >
                  <template v-slot:prepend v-if="notEnoughStock(lot)">
                    <v-tooltip color="danger" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="text-danger" v-bind="attrs" v-on="on"
                          >fa fa-exclamation-circle</v-icon
                        >
                      </template>
                      <span>{{ $t("NOT_ENOUGH_STOCK") }}</span>
                    </v-tooltip>
                  </template></v-text-field
                >
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12" v-if="lot.sendUnitValue > 0">
                <div>
                  <template v-if="notEnoughStock(lot)">
                    <v-tooltip color="danger" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="text-danger" v-bind="attrs" v-on="on"
                          >fa fa-exclamation-circle</v-icon
                        >
                      </template>
                      <span>{{ $t("NOT_ENOUGH_STOCK") }}</span>
                    </v-tooltip>
                  </template>
                  <strong>
                    {{ lot.sendUnitValue }} {{ item.unitShortName }}</strong
                  >
                  ({{ setLabel(item, lot) }})
                </div>
              </v-col>
            </template>
          </v-row>
        </template>
      </template>
      <template v-if="showTotalPrice" slot="body.append">
        <tr class="previewTr">
          <th colspan="100%">
            {{ $t("TOTAL_PRICE") }}:
            {{ itemsTotal }}
          </th>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
.v-data-table >>> td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
</style>
<script>
import _ from "lodash";
export default {
  name: "StockPurchaseOrderPrepareItemsTable",
  inheritAttrs: false,
  props: {
    headers: {
      type: Array,
      default: function() {
        return [];
      }
    },
    items: {
      type: Array,
      default: function() {
        return [];
      }
    },
    itemsTotal: {
      type: Array,
      default: function() {
        return [];
      }
    },
    showTotalPrice: {
      type: Boolean,
      default: true
    },
    statusId: {
      type: Number,
      default: function() {
        return 1;
      }
    }
  },
  data() {
    return {
      dataIndex: 0,
      search: "",
      itemsPerPage: -1,
      rowsPerPageItems: [-1, 100, 10]
    };
  },
  components: {},
  beforeMount() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    preventArrowKeys(event) {
      // Check if the pressed key is an arrow key
      if (["ArrowUp", "ArrowDown"].includes(event.key)) {
        event.preventDefault();
      }
      if (event.key === "," && !event.target.value.includes(".")) {
        event.preventDefault();
        event.target.setAttribute("type", "text");
        event.target.value = event.target.value + ".0";
        event.target.setSelectionRange(
          event.target.value.length - 1,
          event.target.value.length
        );
        event.target.setAttribute("type", "number");
      }
    },
    switchInput(event) {
      const currentInput = event.target;
      const inputs = [...this.$refs.ids];
      const nextIndex = parseInt(currentInput.id.replace("input-", "")) + 2;
      const inputIndex = this.$refs.ids
        .map(item => item._uid)
        .indexOf(nextIndex);

      if (inputIndex !== -1) {
        inputs[inputIndex].focus();
      } else {
        inputs[0].focus();
      }
    },
    checkDiscrepancy(item) {
      if (this.sumSendUnitValue(item) <= 0) {
        return "bg-danger ";
      } else if (item.unitValue != this.sumSendUnitValue(item)) {
        return "bg-warning";
      }
    },
    findInSelect(id, options) {
      console.log(options);
      console.log(id);
      let item = _.find(options, { id: id });
      if (item) {
        return item.name;
      } else {
        return "-";
      }
    },
    setLabel(item, lot) {
      return lot.stockLot + " - " + lot.stockLotExpDate;
    },
    sumSendUnitValue(item) {
      const totalSendUnitValue = item.lots.reduce(
        (total, lot) => total + parseFloat(lot.sendUnitValue),
        0
      );
      return this.formatItemField(item, Math.abs(totalSendUnitValue));
    },
    notEnoughStock(lot) {
      return (
        parseFloat(lot.sendUnitValue) > parseFloat(lot.availableForBookLot)
      );
    },
    filterUnitValue(item, lot) {
      if (
        parseFloat(lot.stockUnitValue) - parseFloat(lot.sumOtherBookedLot) <
        parseFloat(lot.sendUnitValue)
      ) {
        //Взимаме наличността - буукнатите запазени количества, проверяваме дали я надвишаваме и ако да, слагаме стойността да е равна на максимума (едното минус другото)
        lot.sendUnitValue =
          parseFloat(lot.stockUnitValue) - parseFloat(lot.sumOtherBookedLot);

        if (lot.availableForBookLot < 0) {
          lot.sendUnitValue = 0;
        }
      }
      lot.sendUnitValue = this.formatItemField(
        item,
        Math.abs(lot.sendUnitValue)
      );
      lot.sendPackageValue = Math.abs(lot.sendUnitValue / item.packageValue);
    },

    filterPackageValue(item, lot) {
      lot.sendPackageValue = Math.abs(lot.sendPackageValue);
      lot.sendUnitValue = this.formatItemField(
        item,
        Math.abs(lot.sendPackageValue * item.packageValue)
      );
      this.filterUnitValue(item, lot);
    },
    formatItemField(item, value) {
      if (item.unitNumberType == 2) {
        value = Math.round(value);
      } else {
        value = this.$options.filters.formatUnitValue(value);
      }
      return value;
    }
  }
};
</script>
